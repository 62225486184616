import WPhoto_ScotchTopPhotoComponent from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/skinComps/BasicWPhoto/ScotchTopPhoto.skin';
import WPhoto_ScotchTopPhotoController from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/WPhoto.controller';


const WPhoto_ScotchTopPhoto = {
  component: WPhoto_ScotchTopPhotoComponent,
  controller: WPhoto_ScotchTopPhotoController
};


export const components = {
  ['WPhoto_ScotchTopPhoto']: WPhoto_ScotchTopPhoto
};


// temporary export
export const version = "1.0.0"
